import * as React from 'react';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import CardContent from '@mui/joy/CardContent';
import CardActions from '@mui/joy/CardActions';
import CircularProgress from '@mui/joy/CircularProgress';
import Typography from '@mui/joy/Typography';
import SvgIcon from '@mui/joy/SvgIcon';
import EmailIcon from '@mui/icons-material/Email';
import { HEADING_COLOR, TEXT_COLOR } from '../utils/constants';

export default function CustomContact() {
  return (
    <Card variant="solid" style={{backgroundColor:"#f0f4f9"}} invertedColors>
      <CardContent orientation="horizontal">
        
        <CardContent style={{
    display: 'flex',
    flexDirection: 'row',
     
    justifyContent: 'space-between',
    alignItems: 'center'}} >

          <Typography
            variant="h4"
            component="div"
            color={"white"}
            fontSize={"18px"}
            textAlign={"center"}

            style={{ fontWeight: "bold", color: TEXT_COLOR }}
          >
            <strong> {"Write us at:"}</strong>
          </Typography>

          <Typography
            variant="h4"
            component="div"
            color={"white"}
            fontSize={"18px"}
            textAlign={"center"}

            style={{ fontWeight: "bold", color: TEXT_COLOR }}
          >
            <strong> {"info@hiss-tech.com"}</strong>
          </Typography>
 

        </CardContent>
      </CardContent>
    </Card>
  );
}