import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useWindowSize } from "@uidotdev/usehooks";
import ESS_LOGO from "../../public/images/HISST-logo-White.svg";
import HISST_LOGO from "../../public/images/HISS-LOGO-Final.svg";
import { Link } from "react-scroll";
import RequestAccountModal from "../utils/RequestAccountModal";
import {useRouter} from "../routes/hooks";
import { useNavigate, useLocation } from "react-router-dom"; 

const drawerWidth = 240;
const navItems = [
  "Home",
  "About",
  "Contact",
  "Crop Monitoring",
];

function CustomHeader(props) {
  const router = useRouter();
  const windowSize = useWindowSize();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const isPrivacyPolicyPage = location.pathname === "/privacy-policy";
  console.log(isPrivacyPolicyPage);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const handleRequestAccountClick = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const handleNavItemClick = (item) => {
    if (isPrivacyPolicyPage && ["Home", "About", "Contact"].includes(item)) {
      router.push('/');
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      {/* <img src={HISST_LOGO} width={"300px"} /> */}
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
             <ListItemButton onClick={() => handleNavItemClick(item)}>
              {item === "Request Account" ? (
                <Link
                  key={item}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{ textAlign: "center" }}
                >
                  <Button style={{ color: "black" }}>{item}</Button>
                </Link>
              ) : item === "Crop Monitoring" ? (
                <Link
                  key={item}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style={{ textAlign: "center", width: "100%" }}
                >
                  <Button
                    style={{
                      background: "#877849",
                      borderRadius: "10px",
                      boxShadow: "none",
                      color: "#fff",
                      display: "block",
                      fontSize: "13px",
                      fontWeight: 600,
                      margin: 0,
                      padding: "9px 8px",
                      textAlign: "center",
                      width: "100%",
                    }}
                    onClick={() => router.push('/registration')}
                  >        
                    <b  style={{ 
                      padding: "9px 8px" 
                    }}>{item}</b>
                  </Button>
                </Link>
              ) : (
                <Link
                  key={item}
                  to={item.toLowerCase()}
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  onClick={handleDrawerToggle}
                  style={{ textAlign: "center" }}
                >
                  <Button style={{ color: "black" }}><b>{item}</b></Button>
                </Link>
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
 
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar component="nav" style={{backgroundColor: "#ffffff"}}>
          <Toolbar
            disableGutters
            sx={{
              margin: {
                sx: 0,
                sm: "0 auto",
              },   
              width:
                windowSize.width < 992
                  ? "100%"
                  : windowSize.width < 1025
                  ? "85%"
                  : windowSize.width < 1960
                  ? "65%"
                  : "50%",
            }}
          >
            <IconButton
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{
                mr: 2,
                ml: 2,
                color: "white",
                display: { sm: "none" },
                height: { xs: "80px" },
                display: { xs: "flex", sm: "none" },
               
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <MenuIcon style={{ color: '#111418' }} />
              <img
                src={HISST_LOGO}
                style={{
                  width: '100px',
                  
                  height: 'auto',
                  objectFit: 'contain'  
                }}
                alt="HISS Logo"
              />
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{
                display: { xs: "none", sm: "flex" },
                flexGrow: 1,
                height: { xs: '80px', sm: '98px' },  
             
                alignItems: 'center',mt:'10px'
              }}
            >
              <img
                src={HISST_LOGO}
                style={{
                  marginTop:'4px',
                  objectFit: 'contain'
                }}
                alt="HISS Logo"
              />
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) =>
                item === "Request Account" ? (
                  <Link
                    key={item}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textAlign: "center" }}
                  >
                    <Button style={{ color: "#000" }}>{item}</Button>
                  </Link>
                ) : item === "Crop Monitoring" ? (
                  <Link
                    key={item}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textAlign: "center" }}
                  >
                      <Button
                        sx={{
                          background: "#fff",
                          border: "1px solid #877849",
                          borderRadius: "8px",
                          boxShadow: "none",
                          color: "#000",
                          fontSize: "13px",
                          fontWeight: 400,
                          margin: '10px',
                          padding: "10px 10px",
                          textAlign: "center",
                          '&:hover': { // Styles for hover state
                            background: "#877849", // Background color on hover
                            border: "1px solid white", // Border color on hover
                            color: "white", // Text color on hover
                          },
                        }}
                        onClick={() => router.push('/registration')}
                      >
                        <b style={{ paddingLeft: '10px', paddingRight: '10px' }}>{item}</b>
                      </Button>
                  </Link>
                ) : (
                  <Link
                    key={item}
                    to={item.toLowerCase()}
                    spy={true}
                    smooth={true}
                    offset={-70}
                    duration={500}
                    style={{ textAlign: "center"}}
                    onClick={() => handleNavItemClick(item)}
                  >
                    <Button style={{ color: "#000",margin:'10px' }}><b>{item}</b></Button>
                  </Link>
                )
              )}
            </Box>
          </Toolbar>
        </AppBar>
        <nav>
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </nav>
      </Box>
      
    </>
  );
}

CustomHeader.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default CustomHeader;
