import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { CancelOutlined } from "@mui/icons-material";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from '@mui/icons-material/Close';
import { CARD_COLOR, HEADING_COLOR, TEXT_COLOR } from "../utils/constants";

export default function ZoneNumberModal({
  handleVegetationCase,
  indices,
  setNumberOfZones,
  showZoneNumberModal,
  setShowZoneNumberModal,
}) {
  const [number, setNumber] = useState("");
  const [error, setError] = useState("");

  const handleNumberChange = (event) => {
    const value = event.target.value;
    setNumber(value);
    setError("");
  };

  const handleSave = async () => {
    const intValue = parseInt(number, 10);

    if (isNaN(intValue) || intValue < 3 || intValue > 8) {
      setError("Please select a valid number between 3 and 8");
      return;
    }
    setNumberOfZones(intValue);
    setShowZoneNumberModal(false);
    handleVegetationCase(indices, intValue);
  };

  return (
    <Dialog
    
      open={showZoneNumberModal}
      onClose={() => setShowZoneNumberModal(false)}
    >
      <Box
        sx={{
          width: "100%",
          bgcolor: CARD_COLOR,
          borderRadius:1,
          
          boxShadow: 24,
          p: 2,
          color: TEXT_COLOR,
        }}
      >
        <Box style={{ display: "flex", justifyContent: "space-between" , }}>
          <Typography variant="h6" style={{color:TEXT_COLOR}}>Select Number of Zones</Typography>
         
          <CloseIcon
              color={TEXT_COLOR}
            onClick={() => setShowZoneNumberModal(false)}
            style={{
              cursor: "pointer",
              position: "absolute",  
              right: 15,  
            }}
          />

        </Box>
        <DialogContent>
          <Box style={{ width: "100%", display: "flex" }}>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                width: "300px",
                p:2
              }}
            >
              <TextField
                select
                label="Number of Zones"
                variant="outlined"
                fullWidth
                value={number}
                onChange={handleNumberChange}
                error={!!error}
                helperText={error}
                sx={{
                  input: {
                    color: TEXT_COLOR,
                    background: CARD_COLOR,
                    borderRadius: '5px',

                  },
                  mb: 2,
                  '& .MuiOutlinedInput-root': {
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgb(135, 120, 73)",
                    },
                    '&:hover fieldset': {
                      borderColor: 'rgb(115, 100, 60)',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'rgb(115, 100, 60)',
                    },
                  },
                }}
                InputLabelProps={{
                  style: { color: CARD_COLOR },
                }}
                InputProps={{
                  style: { color: TEXT_COLOR },
                }}
              >
                {[3, 4, 5, 6, 7, 8].map((option) => (
                 <MenuItem
                 key={option}
                 value={option}
                 sx={{
                   backgroundColor: CARD_COLOR,  
                   color: TEXT_COLOR,  
                   paddingTop: 0,
                   paddingBottom: 0,
                   "&:hover": {
                     backgroundColor: '#FFF', 
                   },'& .MuiOutlinedInput-root': {
                    "& .MuiOutlinedInput-root": {
                      borderColor: CARD_COLOR, // This applies to the input border
                    },
                    "& .MuiPaper-root": {
                      borderColor:CARD_COLOR, // This applies to the Paper component
                    },
                  },
                 }}
               >
                 <span style={{ padding: 5 }}>{option}</span>
               </MenuItem>
                ))}
              </TextField>
              <Button
                variant="contained"
                sx={{
                  background: HEADING_COLOR,
                  color: 'white',
                  marginTop: '10px',
                  padding: '12px',
                  borderRadius: '10px',
                  '&:hover': {
                    background: 'rgb(115, 100, 60)',  
                  },
                }}
                onClick={handleSave}
              >
                Proceed
              </Button>
              
              
            </Box>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
