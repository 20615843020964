import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { HEADING_COLOR, TEXT_COLOR } from '../utils/constants';

export default function SmallerCard({imageURL,titleText,bodyText}) {
  return (
    <Card sx={{ maxWidth: "100%",backgroundColor:"#f0f0f0",borderRadius:'20px'}}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="auto"
        image={imageURL}
      />
      <CardContent>
        {/* <img
          src={svgURL}
          style={{ marginBottom: "10px" }}
        /> */}
        <Typography
          variant="h6"
          component="div"
          color={HEADING_COLOR}
          mb={1}
          textAlign={"left"}
          style={{ fontWeight: "bold" }}
        >
          <strong>
            {titleText}
          </strong>
        </Typography>
        <Typography
          variant="body1"
          component="div"
          color={TEXT_COLOR}
          mb={3}
          textAlign={"left"}
          style={{ fontWeight: "bold" }}
        >
          <strong>
            {bodyText}
          </strong>
        </Typography>
        {/* <Box style={{ display: "flex" ,flexDirection: "column" }}>
          <Button
            variant="contained"
            style={{
              marginBottom: "10px",
              backgroundColor: "#f27e30",
              width: "100%",
            }}
            size="large"
          >
            Try Now
          </Button>

          <Button variant="outlined" style={{ width: "100%" }} size="large">
            Learn More
          </Button>
        </Box> */}
      </CardContent>
    </Card>
  );
}