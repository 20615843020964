import { Box, Grid, List, ListItem, Typography, Collapse } from "@mui/material";
import { useWindowSize } from "@uidotdev/usehooks";
import service_1 from "../../public/images/service_1.png";
import service_2 from "../../public/images/service_2.png";
import service_3 from "../../public/images/service_3.png";
import FinalCard from "./FinalCard";
import SmallerCard from "./SmallerCard";
import HomeCollapse from "./HomeCollaps";
import Goals_IMG from "../../public/images/Sustainable-Development-Goals.svg";
import ServicesCard from "./services-card";
import { HEADING_COLOR, TEXT_COLOR } from "../utils/constants";

export default function HomeComponent2() {
  const windowSize = useWindowSize();
  return (
    <div id="about">
      <Grid container component="main">
        <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
          <Box
            pt={5}
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                    ? "85%"
                    : windowSize.width < 1960
                      ? "65%"
                      : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: HEADING_COLOR, fontSize: "30px" }}
            >
              <strong>What we do</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: TEXT_COLOR }}
            >
              We are committed to
              fostering sustainable development, enhancing resource management,
              and contributing to a deeper understanding of our planet's
              intricate dynamics. Join us on our mission to unlock the full
              potential of Earth observation and drive positive change for a
              better, more sustainable future. At HISS Tech, we are at the
              forefront of integrating remote sensing, the Internet of Things
              (IoT), and data analytics to pioneer sustainability solutions. Our remote sensing capabilities allow us to capture multidimension big data of the Earth, offering vital insights into climate change, land utilization, and sustainable resource management.
              At HISS Tech, we integrate this big data with IoT and advanced AI analytics to deliver real-time environmental intelligence, monitoring everything from energy consumption to natural habitat conditions.
              We empower businesses,
              municipalities, and organizations to not only track their
              environmental impact but also to make informed decisions that
              contribute to a sustainable future. With HISS Tech, you're not
              just adopting cutting-edge technology; you're investing in a
              sustainable planet.
            </Typography>

            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: HEADING_COLOR, fontSize: "30px" }}
            >
              <strong>Vision</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: TEXT_COLOR }}
            >
              We envision a world where technology empowers communities, businesses, and governments to achieve unprecedented levels of sustainability, resilience, and equity. Through our unwavering commitment to innovation, collaboration, and ethical practices, we aim to create a lasting positive impact on our planet, ensuring a thriving ecosystem and a prosperous, sustainable future for all.
            </Typography>
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: HEADING_COLOR, fontSize: "30px" }}
            >
              <strong>Mission</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={3}
              style={{ color: TEXT_COLOR }}
            >
              Our Mission is to deliver real-time environmental insights using state of the art Earth Observation and AI analytics. We empower our clients with the tools for data driven smart decision-making to achieve sustainability and fostering global positive change through innovative solutions.
            </Typography>
            <Typography
              variant="h5"
              component="div"
              color={"white"}
              style={{ fontWeight: "bold", color: HEADING_COLOR, fontSize: "28px" }}
            >
              <strong>Sustainable Development Goals</strong>
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
              mb={5}
              style={{ color: TEXT_COLOR }}
            >

              At HISS Tech, we are committed to advancing the United Nations Sustainable Development Goals (SDGs) through innovative geospatial and remote sensing technologies. Our mission aligns closely with several key SDGs by providing data-driven solutions that enable sustainable environmental management and informed decision-making.

            </Typography>
            <Box style={{ width: windowSize.width < 992 ? "60%" : "70%", margin: '0 auto' }}>
              <img
                src={Goals_IMG}
                width="100%"
                alt="Sustainable Development Goals"
              />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                    ? "85%"
                    : windowSize.width < 1960
                      ? "65%"
                      : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              mb={1}
              style={{ fontWeight: "bold", color: HEADING_COLOR, fontSize: "30px" }}
            >
              <strong>Our Services</strong>

             
            </Typography>
            <Typography
              variant="body1"
              component="div"
              textAlign={"justify"}
             
              style={{ color: TEXT_COLOR }}
            >

HISS Tech provides an array of services through its developed and deployed digital solutions to support sustainable environmental practices.

            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
          <Box
            style={{
              position: "relative",
              borderRadius: "15px",
              margin: "0 auto",
              marginBottom: "40px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                    ? "85%"
                    : windowSize.width < 1960
                      ? "65%"
                      : "50%",
            }}
          >
            {windowSize.width < 1300 ? (
              <SmallerCard
                imageURL={service_1}
                titleText="Agriculture Monitoring and Precision Agriculture"
                bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. We enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhance crop yields, reducing waste, and improve the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
              />
            ) : (
              <FinalCard
                imageURL={service_1}
                titleText="Agriculture Monitoring and Precision Agriculture"
                bodyText="Our company's remote sensing-based precision agriculture services are designed to transform the agricultural sector by providing advanced, data-driven insights that optimize farming practices. We enable farmers and agribusinesses to monitor crop health, soil conditions, and environmental factors with unprecedented accuracy and detail. This information empowers them to make informed decisions, enhance crop yields, reducing waste, and improve the overall efficiency of their operations. Our services are not just about adopting new technology; they're about embracing a future where agriculture is sustainable, productive, and in harmony with the environment, ensuring prosperity for today's farmers and generations to come."
                leftPosition={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
          <Box
            style={{
              position: "relative",
              borderRadius: "15px",
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "40px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                    ? "85%"
                    : windowSize.width < 1960
                      ? "65%"
                      : "50%",
            }}
          >
            {windowSize.width < 1300 ? (
              <SmallerCard
                imageURL={service_2}
                titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
                bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our state of the art GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
              />
            ) : (
              <FinalCard
                imageURL={service_2}
                titleText="Empower Sustainable Development: Innovative Land and Water Management Services Tailored for Tomorrow"
                bodyText="Our company excels in delivering state-of-the-art land and water management services, integrating remote sensing, Geographic Information Systems (GIS), and automation to champion sustainable development. Utilizing remote sensing, we capture detailed environmental data, which, when analysed through our state of the art GIS, offers comprehensive insights into land and water use efficiency. Automation plays a pivotal role in translating these insights into actionable strategies, streamlining processes for optimal resource management. This synergy of technologies enables our clients to make informed decisions, ensuring the sustainable utilization and conservation of natural resources. Our commitment is to empower businesses, communities, and governments to not only meet their development objectives but also to do so in a way that safeguards the environment for future generations."
                rightPosition={true}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
          <Box
            style={{
              position: "relative",
              borderRadius: "15px",
              margin: "0 auto",
              marginTop: "40px",
              marginBottom: "50px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                    ? "85%"
                    : windowSize.width < 1960
                      ? "65%"
                      : "50%",
            }}
          >
            {windowSize.width < 1300 ? (
              <SmallerCard
                imageURL={service_3}
                titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
                bodyText="Our advanced monitoring services are designed to empower stakeholders in the management and conservation of forests and vegetation, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling our prospective clients and regulators to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
              />
            ) : (
              <FinalCard
                imageURL={service_3}
                titleText="Monitor, Measure, and Monetize: Elevate Your Forest Management with Our Advanced Monitoring for Carbon Credits and Sustainability"
                bodyText="Our advanced monitoring services are designed to empower stakeholders in the management and conservation of forests and vegetation, aligning with the dual goals of earning carbon credits and promoting sustainable development. Utilizing state-of-the-art technology, we provide precise monitoring and data analysis, enabling our prospective clients and regulators to track carbon sequestration, assess forest health, and detect changes over time. This data not only facilitates the generation of carbon credits, offering financial incentives for conservation efforts, but also supports informed decision-making for sustainable forest management. Our approach ensures that forests are valued not just for their timber but as vital carbon sinks and biodiversity reservoirs, contributing to global environmental goals and sustainable development objectives."
                leftPosition={true}
              />
            )}
          </Box>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
          <Box
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                    ? "85%"
                    : windowSize.width < 1960
                      ? "65%"
                      : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <Typography
              variant="h4"
              component="div"
              color={"white"}
             
              style={{ fontWeight: "bold", color: HEADING_COLOR, fontSize: "30px" }}
            >
              <strong>  Bespoke Consulting Solutions</strong>

             
            </Typography>
            
          </Box>
        </Grid>

       
        <Grid item xs={12} style={{ backgroundColor: "#fff" }}>
          <Box
            pt={1}
            pb={5}
            pr={2}
            pl={2}
            style={{
              margin: "0 auto",
              marginBottom: "10px",
              width:
                windowSize.width < 992
                  ? "95%"
                  : windowSize.width < 1025
                    ? "85%"
                    : windowSize.width < 1960
                      ? "65%"
                      : "50%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "start",
            }}
          >
            <ServicesCard />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}
