import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LockIcon from '@mui/icons-material/Lock';
import { Box, Typography } from '@mui/material';
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CARD_COLOR, HEADING_COLOR, TEXT_COLOR } from './constants';

export default function ForgotPasswordModal({
  open, setOpen
}) {

  const [option, setOption] = React.useState(1);
  const [email, setEmail] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [disableButton, setDisableButton] = React.useState(false);

  const handleClose = () => {
    setEmail('');
    setVerificationCode('');
    setNewPassword('');
    setOpen(false);
    setOption(1);
  };

  const showToast = (message, type = 'success') => {
    toast[type](message, {
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: "dark",
    });
  };

  const handleVerifyEmail = async (email) => {
    setDisableButton(true);
    try {
      const response = await fetch('https://escan-systems.com/api/forgetpassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setEmail(email);
        setOption(2);
        showToast('Please check your email for 6-digit verification code.');
      } else if (response.status === 404) {
        showToast('User not found.', 'error');
      } else {
        showToast('Error sending password reset email.', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      showToast('Error sending password reset email.', 'error');
    }
    setDisableButton(false);
  };

  const handleCheckVerificationCode = async () => {
    setDisableButton(true);
    try {
      const response = await fetch('https://escan-systems.com/api/checkVerificationCode', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, verificationCode })
      });

      if (response.ok) {
        setOption(3);
        showToast('Verification code is correct. Please enter your new password.');
      } else if (response.status === 404) {
        showToast('Verification code is incorrect or expired.', 'error');
      } else {
        showToast('Error checking verification code.', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      showToast('Error checking verification code.', 'error');
    }
    setDisableButton(false);
  };

  const handleResetPasswordFinal = async () => {
    setDisableButton(true);
    try {
      const response = await fetch('https://escan-systems.com/api/resetPassword', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, newPassword, verificationCode})
      });

      if (response.ok) {
        showToast('Password reset successfully.', 'success');
        handleClose();
      } else {
        showToast('Error resetting password.', 'error');
      }
    } catch (error) {
      console.error('Error:', error);
      showToast('Error resetting password.', 'error');
    }
    setDisableButton(false);
  };

  const handleEmailSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    if (!email) {
      showToast('Please enter your email.', 'error');
      return;
    }
    handleVerifyEmail(email);
  };

  const handleVerificationCodeSubmit = (event) => {
    event.preventDefault();
    if (!verificationCode) {
      showToast('Please enter the verification code.', 'error');
      return;
    }
    handleCheckVerificationCode();
  };

  const handleNewPasswordSubmit = (event) => {
    event.preventDefault();
    if (!newPassword) {
      showToast('Please enter your new password.', 'error');
      return;
    }
    handleResetPasswordFinal();
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: 'form',
          sx: {
            width: "100%",
            backgroundColor:CARD_COLOR,
            padding:3,
            height:'400px',
            borderRadius:'16px'
          },
          onSubmit: option === 1 ? handleEmailSubmit : option === 2 ? handleVerificationCodeSubmit : handleNewPasswordSubmit
        }}
      >
        <DialogTitle>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"} color={TEXT_COLOR}>
           
            <Typography variant="h5" component={"div"}>Reset Password</Typography>
          </Box>
        </DialogTitle>
        <DialogContent sx={{marginTop:'20px'}}>
          {option === 1 && (
            <DialogContentText display={"flex"} justifyContent={"center"} mb={2} color={TEXT_COLOR} >
              Enter your email to reset your password.
            </DialogContentText>
          )}
          {option === 2 && (
            <DialogContentText display={"flex"} justifyContent={"center"} mb={2} color={TEXT_COLOR}>
              Enter the 6-digit verification code sent to your email.
            </DialogContentText>
          )}
          {option === 3 && (
            <DialogContentText display={"flex"} justifyContent={"center"} mb={2} color={TEXT_COLOR}>
              Enter your new password.
            </DialogContentText>
          )}
          {option === 1 && (
            <TextField
              autoFocus
              required
              margin="dense"
              id="email"
              name="email"
              label="Email Address"
              type="email"
              autoComplete='off'
              fullWidth
              variant="outlined"
              sx={{
                input: {
                 color: TEXT_COLOR,
                  background: CARD_COLOR,
                  borderRadius: "15px",
                },
              }}
              InputLabelProps={{
                style: { color: "#9FB4C7", fontSize: "15px" },
              }}
              InputProps={{ style: { borderRadius: "15px" } }}
            />
          )}
          {option === 2 && (
            <TextField
              autoFocus
              required
              margin="dense"
              id="verificationCode"
              name="verificationCode"
              label="Verification Code"
              type="text"
              autoComplete='off'
              fullWidth
              variant="outlined"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              sx={{
                input: {
                 color: TEXT_COLOR,
                  background: CARD_COLOR,
                  borderRadius: "15px",
                },
              }}
              InputLabelProps={{
                style: { color: "#9FB4C7", fontSize: "15px" },
              }}
              InputProps={{ style: { borderRadius: "15px" } }}
            />
          )}
          {option === 3 && (
            <React.Fragment>
              <TextField
                required
                margin="dense"
                id="newPassword"
                name="newPassword"
                label="New Password"
                type="password"
                autoComplete='off'
                fullWidth
                variant="outlined"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                sx={{
                    input: {
                     color: TEXT_COLOR,
                      background: CARD_COLOR,
                      borderRadius: "15px",
                    },
                  }}
                 InputLabelProps={{
              style: { color: TEXT_COLOR, fontSize: "15px" },
            }}
                  InputProps={{ style: { borderRadius: "15px" } }}
              />

            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
        <Button onClick={handleClose} style={{color:TEXT_COLOR}} >Cancel</Button>
          <Button variant="contained" type="submit" style={{backgroundColor:HEADING_COLOR} } disabled={disableButton}>{option === 1 ? "Verify Email" : (option === 2 ? "Enter Code" : "Reset Password")}</Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </React.Fragment>
  );
}


