import * as React from 'react';
import { Box, Grid, Link, Typography, Container, IconButton } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useWindowSize } from "@uidotdev/usehooks";
import moment from 'moment/moment';
import { TEXT_COLOR } from '../utils/constants';

// Replace these with your own social media URLs
const socialMediaLinks = {
  facebook: '#',
  twitter: '#',
  instagram: '#',
};

const CustomFooter = () => {
  const windowSize = useWindowSize();
  return (
    <Box
      sx={{
        
        bgcolor: '#fff',
        color: 'text.secondary',
        py: 3,
        borderTop: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Container maxWidth={false}
      sx={{
        
        width:
        windowSize.width < 992
          ? "100%"
          : windowSize.width < 1025
          ? "85%"
          : windowSize.width < 1960
          ? "65%"
          : "50%",
      }}
      >
        {/* <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="white" gutterBottom>
              Brand Name
            </Typography>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              PRODUCT
            </Typography>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Features</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Integrations</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Pricing</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>FAQ</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              COMPANY
            </Typography>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>About Us</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Careers</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Privacy Policy</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Terms of Service</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              DEVELOPERS
            </Typography>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Public API</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Documentation</Link>
            <Link href="#" color="inherit" display="block" style={{color:'white'}}>Guides</Link>
          </Grid>
          <Grid item xs={6} sm={3} md={2}>
            <Typography variant="subtitle1" color="white" gutterBottom>
              SOCIAL MEDIA
            </Typography>
            <IconButton aria-label="Facebook" color="inherit" component="a" href={socialMediaLinks.facebook}>
              <FacebookIcon style={{color:'white'}} />
            </IconButton>
            <IconButton aria-label="Twitter" color="inherit" component="a" href={socialMediaLinks.twitter}>
              <TwitterIcon style={{color:'white'}}/>
            </IconButton>
            <IconButton aria-label="Instagram" color="inherit" component="a" href={socialMediaLinks.instagram}>
              <InstagramIcon style={{color:'white'}}/>
            </IconButton>
          </Grid>
        </Grid> */}
        <Grid container spacing={2} justifyContent="space-between">
          <Grid item xs={6} sm={6} md={6}>
            <Typography variant="body2" color="white" align="left" sx={{ pt: 1 ,color:TEXT_COLOR}}>
              <strong>© {moment().year()} Hiss-Tech. All rights reserved.</strong>
            </Typography>
          </Grid>
          <Grid item xs={6} sm={6} md={6}  align="right" >
            <Link href="/privacy-policy" color="inherit" display="block" style={{color:TEXT_COLOR, paddingTop: '4px'}} >Privacy Policy</Link>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CustomFooter;