import { Grid, Paper, Typography } from '@mui/material';
import icon_IMG from "../../public/images/demo-icon.svg"
import CO2 from "../../public/images/CO2.svg"
import FLOOD from "../../public/images/FLOOD.svg"
import MINERAL from "../../public/images/MINERAL.svg"
import DISASTER from "../../public/images/DISASTER.svg"
import LOGI from "../../public/images/LOGI.svg"
import TRAING from "../../public/images/TRAING.svg"

import { HEADING_COLOR, TEXT_COLOR } from '../utils/constants';
/* 

 "Agriculture and Forestry": {
      items: [
        "Mapping and Monitoring Food Security",
        "Precision Agriculture",
        "Crop Monitoring and Management",
        "Farm Management Solutions",
        "Weather and Climate Analysis",
        "Field Mapping and Surveying",
        "Forest Management",
        "Sustainable Land Use Planning",
        "Irrigation and Water Management",
        "Environmental Impact Assessment",
        "Green Supply Chain Management"
      ],
      image: icon_IMG
    },  
    "Carbon Management and Offsetting": {
      items: [
        "Carbon Footprint Analysis",
        "Carbon Monitoring and Reporting",
        "Carbon Offset Solutions",
        "Energy Efficiency Solutions",
        "Renewable Energy Integration",
        "Sustainable Supply Chain Management",
        "Carbon Accounting Software",
        "Environmental Impact Assessment",
        "Climate Risk Analysis",
        "Carbon Neutral Certification",
        "Sustainable Agriculture Practices",
        "Corporate Sustainability Strategy",
        "Public Awareness and Education",
        "Regulatory Compliance and Advocacy"
      ],
      image: CO2
    },
    
    
    */
const data = { 
    "Flood Monitoring and Management": {
      items: [
        "Flood Risk Mapping",
        "Real-Time Monitoring Systems",
        "Early Warning Systems",
        "Hydrological Modeling",
        "Data Integration and Management",
        "Emergency Response Planning",
        "Public Awareness and Education",
        "Infrastructure Resilience Analysis",
        "Post-Flood Damage Assessment",
        "Urban Flood Management",
        "Floodplain Management",
        "Climate Change Impact Assessment",
        "Community-Based Flood Management",
        "Insurance and Risk Financing",
        "Sustainable Water Management"
      ],
      image: FLOOD
    }, 
    "Disaster Management": {
      items: [
        "Hazard Mapping and Modeling",
        "Early Warning Systems",
        "Risk Assessment and Analysis",
        "Disaster Preparedness Planning",
        "Remote Sensing and Satellite Monitoring",
        "Geospatial Data Integration",
        "Emergency Response Coordination",
        "Infrastructure Resilience Analysis",
        "Community-Based Risk Reduction",
        "Post-Disaster Recovery and Reconstruction",
        "Climate Change Adaptation",
        "Policy Support and Advocacy"
      ],
      image: DISASTER
    }, 
    "Mineral Exploration": {
      items: [
        "Remote Sensing and Satellite Imagery",
        "Drone Surveys",
        "Geophysical Surveys",
        "Mapping",
        "3D Modeling and Visualization",
        "Data Integration and Management",
        "Machine Learning and AI",
        "Exploration Software Development",
        "Environmental Impact Assessment",
        "Resource Estimation and Reporting",
        "Hydrogeological Studies",
        "Regulatory Compliance and Permitting",
        "Community Engagement and CSR"
      ],
      image: MINERAL
    }, 
    "Transportation and Logistics": {
      items: [
        "Route Optimization",
        "Fleet Management",
        "Supply Chain Visibility",
        "Logistics Planning and Coordination",
        "Data Analytics and Reporting"
      ],
      image:LOGI
    },
    "Training and Development": {
      items: [
        "Real Estate and Property Management",
        "Location-Based Services (LBS)",
        "Urban Planning and Smart Cities",
        "Environmental Monitoring",
        "Utilities and Infrastructure",
        "Natural Resource Management",
        "Public Health and Epidemiology",
        "Military and Defense"
      ],
      image:TRAING
    },
  };
  function ServicesCard() {
    return (
      <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
        {Object.keys(data).map((title, index) => (
          <Grid item xs={2} sm={4} md={4} key={index} style={{ backgroundColor: "#fff" }}>
            <Paper 
            elevation={3} 
            style={{ 
              padding: '16px', 
              backgroundColor: "#f0f4f9",
              display: 'flex',
              flexDirection: 'column',
              height: '100%' ,
              borderRadius: "8px" ,boxShadow:'none',
            }}
          >
            <img
                src={data[title].image}
                alt={title}
                style={{ width: '60px', height: 'auto', margin: '0 auto 16px', display: "block" }}
              />
            <Typography
              variant="h4"
              component="div"
              color={"white"}
              fontSize={"18px"}
              textAlign={"center"}
              mb={2}
              style={{ fontWeight: "bold", color: HEADING_COLOR }}
            >
              <strong> {title}</strong>
            </Typography>
             
              <ul>
                {data[title].items.map((item, itemIndex) => (
                  <li key={itemIndex} style={{color: TEXT_COLOR }}>
                    <Typography variant="body1" style={{fontSize: "14px", lineHeight: "20px"}}>{item}</Typography>
                  </li>
                ))}
              </ul>
            </Paper>
          </Grid>
        ))}
      </Grid>
    );
  }
  
  export default ServicesCard;